import { env } from '../Constant/env';
import { isIRA } from '../Constant/IRAPanelData';
import { CreateAdminProps, UserDetailsProps } from '../Constant/Types/types';
import { instance } from './interceptor';
import axios from 'axios';

export const IRA_ADMIN = {
  login: async (email: string, password: string) => {
    const res = instance.post(`${env.ENDPOINT}onboarding/login/`, { email, password });
    return (await res).data;
  },
  getAllIraUsers: async (signal: AbortSignal) => {
    const res = instance.get(`${env.ENDPOINT}onboarding/get_all_ira_users/`, { signal });
    return (await res).data;
  },
  getAllUsers: async (signal: AbortSignal) => {
    const res = instance.get(`${env.ENDPOINT}onboarding/get_all_users/`, { signal });
    return (await res).data;
  },
  getFilteredUsers: async (signal: AbortSignal, duration: string, route?: string) => {
    const res = instance.get(`${env.ENDPOINT}onboarding/${duration}/${route}/`, { signal });
    return (await res).data;
  },
  getUser: async (userId: number) => {
    // const res = instance.get<UserDetailsProps>(`${env.ENDPOINT}onboarding/${directory === 'ira' ? 'get_ira_user' : 'get_user'}/${userId}/`);
    const res = instance.get<UserDetailsProps>(`${env.ENDPOINT}onboarding/get_user/${userId}/`);
    return (await res).data;
  },
  verifyOTP: async (token: number, user_id: number) => {
    const res = instance.post(`${env.ENDPOINT}onboarding/verify-otp/`, { user_id, token });
    return (await res).data;
  },
  changePassword: async (password: string, newPassword: string, userId: number) => {
    const data = {
      old_password: password,
      new_password: newPassword,
    };
    const res = instance.post(`${env.ENDPOINT}onboarding/change_password/${userId}/`, data);
    return (await res).data;
  },
  resetByEmail: async (email: string) => {
    const res = instance.post(`${env.ENDPOINT}onboarding/request-reset-email/`, {
      email,
    });
    return (await res).data;
  },
  informUserByEmail: async (email: string, emailContent: string, userId: number) => {
    const res = instance.post(`${env.ENDPOINT}onboarding/inform_user_email/${userId}/`, {
      to_email: email,
      message: emailContent,
    });
    return (await res).data;
  },
  approveUserApplication: async (userId: number, investor_type: string) => {
    const url = isIRA(investor_type) ? 'carl/verification-webhook/' : 'onboarding/approve_user/';
    const res = instance.post(`${env.ENDPOINT}${url}`, {
      id: userId,
    });
    return (await res).data;
  },
  rejectUserApplication: async (userId: number, investor_type: string) => {
    const url = isIRA(investor_type) ? 'carl/verification-webhook/' : 'onboarding/approve_user/';
    const res = instance.post(`${env.ENDPOINT}${url}`, {
      id: userId,
      reject: true,
    });
    return (await res).data;
  },

  updateUserDetails: async (updatedData: any, userId: number, investor_type: string) => {
    const res = instance.post(`${env.ENDPOINT}onboarding/${isIRA(investor_type) ? 'ira_' : ''}user_update/${userId}/`, updatedData);
    return (await res).data;
  },
  createAdmin: async (admindata: CreateAdminProps) => {
    const { adminEmail, phoneNumber, adminPassword, firstName, lastName } = admindata;
    const data = {
      email: adminEmail,
      password: adminPassword,
      phone_number: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      role: 'ADMIN',
    };
    const res = instance.post(`${env.ENDPOINT}onboarding/register-admin/`, data);
    return (await res).data;
  },
  resetPassword: async (password: string, uidb64: string | undefined, token: string | undefined) => {
    const res = instance.post(`${env.ENDPOINT}onboarding/password-reset-complete/`, { password, uidb64, token });
    return (await res).data;
  },
  deleteOtherDocument: async (document_name: string, userId: string) => {
    const data = {
      document_name,
      delete: true,
    };
    const res = instance.post(`${env.ENDPOINT}onboarding/other_document/${userId}/`, data);
    return (await res).data;
  },
  deleteThirdpartyDocument: async (document_name: string, userId: string) => {
    const data = {
      document_name,
      delete: true,
    };
    const res = instance.post(`${env.ENDPOINT}onboarding/third_party_documents/${userId}/`, data);
    return (await res).data;
  },

  // Document Api's

  KYCSubmit: async (data: any, userId: number, investor_type: string) => {
    const { documentType, frontImage, backImage } = data;
    const body = new FormData();
    body.append('documentType', documentType);
    body.append('frontImage', frontImage);
    if (backImage !== null) {
      body.append('backImage', backImage);
    }
    const res = instance.post(`${env.ENDPOINT}onboarding/${isIRA(investor_type) ? 'ira_users_kyc' : 'users_kyc'}/${userId}/`, body);
    return (await res).data;
  },
  addressDocument: async (data: any, userId: number, investor_type: string) => {
    const { document_type, address_proof } = data;
    const body = new FormData();
    body.append('document_type', document_type);
    body.append('address_proof', address_proof);
    const res = instance.post(
      `${env.ENDPOINT}onboarding/${isIRA(investor_type) ? 'ira_address_proof' : 'address_proof_user'}/${userId}/`,
      body,
    );
    return (await res).data;
  },
  accreditedDocument: async (data: any, docId: string) => {
    const { image, imageTitle } = data;
    const body = new FormData();
    body.append('image', image);
    body.append('imageTitle', imageTitle);
    const res = instance.post(`${env.ENDPOINT}onboarding/accredited_investor_update/${docId}/`, body);
    return (await res).data;
  },
  authProofDocument: async (data: any, userId: number) => {
    const { proof_of_auth } = data;
    const body = new FormData();
    body.append('proof_of_auth', proof_of_auth);
    const res = instance.post(`${env.ENDPOINT}onboarding/proof_of_auth_user/${userId}/`, body);
    return (await res).data;
  },
  otherDocument: async (data: any, userId: number) => {
    const { other_document, document_name } = data;
    const body = new FormData();
    body.append('other_document', other_document);
    body.append('document_name', document_name);
    const res = instance.post(`${env.ENDPOINT}onboarding/other_document/${userId}/`, body);
    return (await res).data;
  },
  thirdPartyDocument: async (data: any, userId: number) => {
    const { other_document, document_name } = data;
    const body = new FormData();
    body.append('third_party_document', other_document);
    body.append('document_name', document_name);
    const res = instance.post(`${env.ENDPOINT}onboarding/third_party_documents/${userId}/`, body);
    return (await res).data;
  },
  transactionActive: async (transactionData: any, userId: any) => {
    const { start_date, end_date, amount, transactions_type, transaction_document } = transactionData;
    const body = new FormData();
    body.append('user_id', userId);
    body.append('amount', amount);
    body.append('start_date', start_date);
    body.append('end_date', end_date);
    body.append('transaction_document', transaction_document);
    body.append('transactions_type', transactions_type);
    const res = instance.post(`${env.ENDPOINT}carl/transaction_active_nonus/`, body);
    return (await res).data;
  },
  transactionProcess: async (userId: number) => {
    const res = instance.post(`${env.ENDPOINT}carl/transaction_processed_nonus/`, { user_id: userId });
    return (await res).data;
  },
  getLeadsUsers: async (page: number) => {
    const res = instance.get(`${env.ENDPOINT}onboarding/leads_user/${page}/`);
    return (await res).data;
  },
  getLeadUserById: async (userId: number) => {
    const res = instance.get(`${env.ENDPOINT}onboarding/lead_user_validate_phone/${userId}/`);
    return (await res).data;
  },
  entityFundTransactionList: async (signal: AbortSignal, route: string, page: number) => {
    const res = instance.get(`${env.ENDPOINT}onboarding/nonus_fund_transaction/${route}/${page}/`, { signal });
    return (await res).data;
  },
  fundsTransactionUpdate: async (updatedData: any) => {
    const { user_id, transaction_id, amount, start_date, end_date, transaction_document, transactions_type } = updatedData;
    const body = new FormData();
    body.append('user_id', user_id);
    body.append('transaction_id', transaction_id);
    body.append('amount', amount);
    body.append('start_date', start_date);
    body.append('end_date', end_date);
    if (transaction_document) {
      body.append('transaction_document', transaction_document);
    }
    body.append('transactions_type', transactions_type);
    const res = instance.post(`${env.ENDPOINT}carl/nonus_fund_transaction_update/`, body);
    return (await res).data;
  },
  approvedTransaction: async (user_id: number, transaction_id: number, transactions_type: string) => {
    const data = {
      user_id,
      transaction_id,
      transactions_type,
    };
    const res = instance.post(`${env.ENDPOINT}carl/transaction_processed_nonus/`, data);
    return (await res).data;
  },
  rejectTransaction: async (user_id: number, transaction_id: number, transactions_type: string) => {
    const data = {
      user_id,
      transaction_id,
      transactions_type,
      reject: true,
    };
    const res = instance.post(`${env.ENDPOINT}carl/transaction_processed_nonus/`, data);
    return (await res).data;
  },
  getUserSpecificTransactions: async (userId: number) => {
    const res = instance.get(`${env.ENDPOINT}onboarding/get_user_transaction_detail/${userId}/`);
    return (await res).data;
  },
  searchUser: async (val: object) => {
    const res = instance.post(`${env.ENDPOINT}onboarding/search_user/`, val);
    return (await res).data;
  },
  dashboard: async (signal?: AbortSignal) => {
    const res = instance.get(`${env.ENDPOINT}crm/dashboard/`, { signal });
    return (await res).data;
  },
  investedUsers: async (strategy_id: number) => {
    const res = instance.get(`${env.ENDPOINT}crm/user_strategy_dashboard/${strategy_id}/`);
    return (await res).data;
  },
  investors: async (signal?: AbortSignal) => {
    const res = instance.get(`${env.ENDPOINT}crm/existing_investors/`, { signal });
    return (await res).data;
  },
  getSupportRequests: async () => {
    const res = instance.get(`${env.ENDPOINT}crm/support_request/`);
    return (await res).data;
  },
  createSupportRequest: async (payload: any) => {
    const res = instance.post(`${env.ENDPOINT}crm/support_request/`, payload);
    return (await res).data;
  },
  getAllStrategies: async () => {
    const res = instance.get(`${env.ENDPOINT}strategies/info/`);
    return (await res).data;
  },
  getPortfolio: async (userId: number) => {
    const res = instance.get(`${env.ENDPOINT}crm/portfolio_management/${userId}/`);
    return (await res).data;
  },
  createPortfolio: async (payload: any, userId: number) => {
    const res = instance.post(`${env.ENDPOINT}crm/portfolio_management/${userId}/`, payload);
    return (await res).data;
  },
  verifyPortfolio: async (payload: any, userId: number) => {
    const res = instance.put(`${env.ENDPOINT}crm/portfolio_management/${userId}/`, payload);
    return (await res).data;
  },
  deletePortfolio: async (payload: any, userId: number) => {
    // delete API is not working from instance
    // const res = instance.delete(`${env.ENDPOINT}crm/portfolio_management/${userId}/`, payload);
    const token = sessionStorage.getItem('token');
    const res = await axios.delete(`${env.ENDPOINT}crm/portfolio_management/${userId}/`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      data: payload,
    });
    return (await res).data;
  },
  portfolioEditHistory: async (userId: number) => {
    const res = instance.get(`${env.ENDPOINT}crm/user_portfolio_history/${userId}/`);
    return (await res).data;
  },
  getLogs: async (userId: number) => {
    const res = instance.get(`${env.ENDPOINT}onboarding/user-log/${userId}/`);
    return (await res).data;
  },
  getNotes: async (userId: number) => {
    const res = instance.get(`${env.ENDPOINT}onboarding/notes/${userId}/`);
    return (await res).data;
  },

  noteDocs: async (data: any, userId: number) => {
    const { document_type, docs_proof } = data;
    const body = new FormData();
    body.append('document_type', document_type);
    body.append('user_notes', docs_proof);
    const res = instance.post(`${env.ENDPOINT}onboarding/notes_user/${userId}/`, body);
    return (await res).data;
  },
  createNotes: async (payload: any) => {
    const body = new FormData();
    body.append('user_id', payload.user_id);
    body.append('description', payload.description);
    body.append('document', payload.document);
    const res = instance.post(`${env.ENDPOINT}onboarding/notes/`, body);
    return (await res).data;
  },
  updateNotes: async (payload: any) => {
    const body = new FormData();
    body.append('user_id', payload.user_id);
    body.append('note_id', payload.note_id);
    body.append('description', payload.description);
    body.append('document', payload.document);
    const res = instance.post(`${env.ENDPOINT}onboarding/notes/`, body);
    return (await res).data;
  },
  deleteNotes: async (payload: any, userId: number) => {
    const token = sessionStorage.getItem('token');
    const res = await axios.delete(`${env.ENDPOINT}onboarding/notes/${userId}/${payload.note_id}/`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
    return (await res).data;
  },
  getCertificateUrl: async (userId: number) => {
    const res = instance.get<UserDetailsProps>(`${env.ENDPOINT}onboarding/get_certificate_url/${userId}/`);
    return (await res).data;
  },
  updateCertificateReleaseStatus: async (payload: any) => {
    const res = instance.post(`${env.ENDPOINT}onboarding/validate_code_create_certificate/`, payload);
    return (await res).data;
  },
};
