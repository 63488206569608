import { useState } from 'react';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { IRA_ADMIN } from '../../../Api/panelApis';
import Button from '../../../components/Buttons/button';
import Dropdown from '../../../components/dropdown/dropdown';
import EditableInput from '../../../components/InputFields/EditableInput';
import { exceptThisSymbols, TRANSACTION_TYPE } from '../../../Constant/IRAPanelData';
import { toastError, toastSuccess } from '../../../Utils/helper';
import { transactionSchema } from '../../../Validation/validationScema';
import EditableFiles from '../../../components/InputFields/EditFiles';
import PdfImage from '../../../components/DocumentImage/pdfImage';
import DocumentImage from '../../../components/DocumentImage/documentImage';
import { ThreeDots } from 'react-loader-spinner';

interface transactionTypes {
  start_date: string;
  end_date: string;
  amount: number;
}

const TransactionSection = ({ userId }: any) => {
  const [tranactionType, setTranactionType] = useState('Deposit');
  const [documentFileTypeURL, setDocumentFileTypeURL] = useState('');
  const [documentFileType, setDocumentFileType] = useState<any>('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues: transactionTypes = {
    start_date: dayjs(new Date()).format('YYYY-MM-DD'),
    end_date: dayjs(new Date()).add(2, 'day').format('YYYY-MM-DD'),
    amount: 1,
  };

  const convertBase64 = (image: Blob, setValue: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(image);
      try {
        fileReader.onload = () => {
          resolve(fileReader.result);
          setValue(fileReader.result);
        };
      } catch {
        fileReader.onerror = (error: any) => {
          reject(error);
        };
      }
    });
  };

  const submitTransaction = async (updatedValues: transactionTypes) => {
    setIsLoading(true);
    const transactionData = {
      start_date: dayjs(updatedValues.start_date).format('YYYY-MM-DD'),
      end_date: dayjs(updatedValues.end_date).format('YYYY-MM-DD'),
      amount: updatedValues.amount,
      transactions_type: tranactionType.toLowerCase(),
      transaction_document: documentFileType,
    };
    try {
      const response = await IRA_ADMIN.transactionActive(transactionData, userId);
      if (response.Success) {
        toastSuccess('Transaction Add Successfully');
        setIsLoading(false);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    } catch (error: any) {
      toastError(error?.response?.data?.Error || 'Something went wrong');
    }
    setIsLoading(false);
  };

  const handleClick = () => {
    console.log('Clicked');
  };

  return (
    <div className='my-10'>
      <div className='mx-4 pb-4 px-3'>
        <h2 className='text-lg text-blue-600 font-medium mt-8 heading'>TRANSACTION SECTION</h2>
      </div>
      <hr className='hrLine' />
      {isLoading ? (
        <div className='alignment h-full' style={{ height: '70vh' }}>
          <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
        </div>
      ) : (
        <div className='mt-6 px-6'>
          <Formik
            initialValues={initialValues}
            validationSchema={transactionSchema}
            validateOnBlur={true}
            onSubmit={updatedValues => {
              submitTransaction(updatedValues);
            }}
            validateOnChange={true}
          >
            {({ values: transactionDetails, errors, handleChange, handleSubmit }) => (
              <Form>
                <div className='grid mx-6 grid-cols-3'>
                  <EditableInput
                    value={transactionDetails.amount}
                    onChange={handleChange}
                    className='inputStyle'
                    type='number'
                    heading='Amount'
                    name='amount'
                    onKeyDown={(e: any) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    error={errors.amount}
                  />
                  <EditableInput
                    value={dayjs(transactionDetails.start_date).format('YYYY-MM-DD')}
                    onChange={handleChange}
                    className='inputStyle'
                    type='date'
                    name='start_date'
                    heading='Start Date'
                    error={errors.start_date}
                  />

                  <EditableInput
                    value={dayjs(transactionDetails.end_date).format('YYYY-MM-DD')}
                    onChange={handleChange}
                    className='inputStyle'
                    type='date'
                    name='end_date'
                    heading='End Date'
                    error={
                      // dayjs(transactionDetails.start_date).diff(dayjs(transactionDetails.end_date).format('YYYY-MM-DD'), 'day') > -2
                      // ? 'End Date should be 2 days greater than Start Date'
                      // :
                      errors.end_date
                    }
                  />
                  <div className={`w-3/12 p-1 text-left editableFieldSelect`}>
                    <p className='text-md headingText'>Transaction Type</p>
                    <Dropdown
                      items={TRANSACTION_TYPE}
                      selected={tranactionType}
                      captionKey='value'
                      className={`w-3/12 mt-1 text-darkblue border-b-pink font-extrabold inputStyle cursor-pointer editableFieldSelect`}
                      handler={setTranactionType}
                      title='Select Transaction Type'
                    />
                  </div>
                </div>
                <div className='flex-col alignment my-6'>
                  {documentFileType?.name || documentFileType?.name ? (
                    <>
                      {documentFileType?.type === 'application/pdf' ? (
                        <PdfImage title={documentFileType?.name} />
                      ) : (
                        <DocumentImage src={documentFileTypeURL} />
                      )}
                    </>
                  ) : null}
                  <EditableFiles
                    type='file'
                    imageUrl={documentFileType}
                    onChange={(e: any) => {
                      convertBase64(e.target.files[0], setDocumentFileTypeURL), setDocumentFileType(e.target.files[0]);
                    }}
                    name='documentFile'
                    placeholder={documentFileTypeURL ? null : '(You can upload an image file or a pdf document)'}
                    title={documentFileTypeURL ? 'Change Document' : 'Upload'}
                    parentStyle={documentFileTypeURL ? null : 'bg-grey h-52 p-5 rounded-lg'}
                    uploadButtonStyle={documentFileTypeURL ? 'bg-transparent text-darkblue  w-40' : 'bg-pink'}
                    docTypes={'application/pdf'}
                    onclick={handleClick}
                  />
                </div>
                <div className='alignment mt-6'>
                  <Button
                    text='Add Transaction'
                    style='m-1 w-40 text-center bg-darkblue'
                    disabled={
                      Boolean(Object.keys(errors).length || !documentFileType || !documentFileTypeURL)
                      // || dayjs(transactionDetails.start_date).diff(dayjs(transactionDetails.end_date).format('YYYY-MM-DD'), 'day') > -2
                    }
                    onClick={handleSubmit}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default TransactionSection;
