import { useMemo, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Investors_COLUMNS } from '../../../Constant/Column/column';

import { Link } from 'react-router-dom';
import { Listbox } from '@headlessui/react';
import { MdRemoveRedEye } from 'react-icons/md';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

const InvestorsTable = ({ investors, pageNumber, usersPerPage }: any) => {
  const [checkButton, setcheckButton] = useState('');

  // Custom sorting function for case insensitive sorting
  const caseInsensitiveSort = (rowA: any, rowB: any, columnId: string) => {
    const valueA = rowA.values[columnId].toString().toLowerCase();
    const valueB = rowB.values[columnId].toString().toLowerCase();
    return valueA.localeCompare(valueB);
  };

  const columns = useMemo<any>(() => {
    return Investors_COLUMNS.map(column => {
      // if (!column.disableSortBy) {
      return {
        ...column,
        // Use the custom sorting function for columns that allow sorting
        sortType: caseInsensitiveSort,
      };
      // }
      // return column;
    });
  }, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: investors,
    },
    useSortBy,
  );

  // Slice rows based on pagination values
  const pagesVisited = pageNumber * usersPerPage;
  const paginatedRows = rows.slice(pagesVisited, pagesVisited + usersPerPage);

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, colKey) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={colKey}>
                  <div className='flex items-center justify-center mx-auto'>
                    <span>{column.render('Header')}</span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaSortDown className='ml-1' />
                      ) : (
                        <FaSortUp className='ml-1' />
                      )
                    ) : (
                      <FaSort className='ml-1' />
                    )}
                  </div>
                </th>
              ))}
              <th>View</th>
            </tr>
          ))}
        </thead>
        {investors.length ? (
          <tbody {...getTableBodyProps()}>
            {paginatedRows.map((row, index) => {
              prepareRow(row);
              const originalData = JSON.parse(JSON.stringify(row.original));
              return (
                <>
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell: any, colKey) => {
                      return (
                        <td {...cell.getCellProps()} key={colKey}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                    <td className='text-center w-4'>
                      <Listbox value={checkButton} onChange={setcheckButton}>
                        <Listbox.Button className='text-center mx-auto'>
                          <Link to={`/UserDetailsInvestors/${originalData.id}`} state={JSON.stringify(originalData)}>
                            <div className='mx-2'>
                              <MdRemoveRedEye />
                            </div>
                          </Link>
                        </Listbox.Button>
                      </Listbox>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <th className='relative left-1/3 '>No matching records found</th>
            </tr>
          </tbody>
        )}
      </table>
    </>
  );
};

export default InvestorsTable;
