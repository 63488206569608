import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AiFillDashboard, AiFillSetting } from 'react-icons/ai';
import { FaMoneyCheckAlt, FaUsers } from 'react-icons/fa';
import './sideBar.css';

export const usePreviousRoute = (): string | null => {
  const [previousRoute, setPreviousRoute] = useState<string | null>(null);
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    return () => {
      setPreviousRoute(currentPath);
      localStorage.setItem('previousRoute', currentPath);
    };
  }, [currentPath]);

  return previousRoute;
};

// Mapping for active routes by section
const routeMapping: Record<string, string[]> = {
  '/dashboard': ['/dashboard', '/strategy_details'],
  '/investors': ['/investors', '/UserDetailsInvestors', '/editDetails'],
  '/applications': ['/us_user', '/UserDetailsApplications', '/editDetails'],
  '/support_requests': ['/support_requests'],
};

const SideBar: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const previousRoute = usePreviousRoute(); // Get the previous route

  // Function to check if a route is active
  const isActiveRoute = (parentRoute: string): boolean => {
    const currentPath = location.pathname;
    return routeMapping[parentRoute]?.some(route => currentPath.startsWith(route));
  };

  // Example: Use the previousRoute for debugging or tracking purposes
  useEffect(() => {
    if (previousRoute) {
      console.log(`User navigated from ${previousRoute}`);
    }
  }, [previousRoute]);

  return (
    <div className='panelSidebar'>
      <div className='logo'>
        <img src='/Assets/carl-logo.png' alt='carl-logo' />
        <p className='text-white mx-12 text-sm'>Admin Panel</p>
      </div>
      <div className='categoriesContainer'>
        <aside className='w-64' aria-label='Sidebar'>
          <div className='py-4 px-3 rounded bg-#091b41'>
            <ul className='space-y-2'>
              <li>
                <NavLink
                  to='/dashboard'
                  className={({ isActive }) =>
                    `${
                      isActive || isActiveRoute('/dashboard') ? 'isActive' : 'not-active'
                    } flex items-center p-2 fa-10x text-xl font-normal rounded-lg`
                  }
                >
                  <AiFillDashboard />
                  <span className='ml-3'>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/investors'
                  className={({ isActive }) =>
                    `${
                      isActive ||
                      (isActiveRoute('/investors') &&
                        ((previousRoute?.includes('/') && currentPath?.includes('/UserDetailsInvestors')) ||
                          previousRoute?.includes('/investors') ||
                          previousRoute?.includes('/UserDetailsInvestors')))
                        ? 'isActive'
                        : 'not-active'
                    } flex items-center p-2 fa-10x text-xl font-normal rounded-lg`
                  }
                >
                  <FaMoneyCheckAlt />
                  <span className='ml-3'>Verified Investors</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/us_user'
                  className={({ isActive }) =>
                    `${
                      isActive ||
                      (isActiveRoute('/applications') &&
                        ((previousRoute?.includes('/') && currentPath?.includes('/UserDetailsApplications')) ||
                          previousRoute?.includes('/us_user') ||
                          previousRoute?.includes('/UserDetailsApplications')))
                        ? 'isActive'
                        : 'not-active'
                    } flex items-center p-2 fa-10x text-xl font-normal rounded-lg`
                  }
                >
                  <FaUsers />
                  <span className='ml-3'>Applications</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/support_requests'
                  className={({ isActive }) =>
                    `${
                      isActive || isActiveRoute('/support_requests') ? 'isActive' : 'not-active'
                    } flex items-center p-2 fa-10x text-xl font-normal rounded-lg`
                  }
                >
                  <AiFillSetting />
                  <span className='ml-3'>Support Requests</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default SideBar;
