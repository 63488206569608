import Details from '../../../components/Details/details';
// import { UserDetailsProps } from '../../../Constant/Types/types';
import DocumentImage from '../../../components/DocumentImage/documentImage';
import '../userDetails.css';
import PdfPreview from '../../../components/DocumentImage/pdfPreview';
import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';
import UploadOtherDocument from './uploadOtherDocument';
// import UserDetails from '../userDetails';

// interface OtherDocumentProps {
//   document_url: string;
// }
// interface UserDetailsProp {
//   userDetails: UserDetailsProps;
// }
// interface OtherDocumentProps {
//   document_name: string;
//   document_url: string;
// }
const allDocument = ({ userDetails, userId }: any) => {
  return (
    <CollapsibleContainer title='DOCUMENTS'>
      {/* <div className='mt-6 px-6'>
        <Details heading='Document Name' detail={userDetails?.accreditedinvestordata && 'Accredited Investor'} />
        <div className='alignmentAround m-4 flex-wrap float-left'>
          {Object.keys(userDetails?.accreditedinvestordata)?.map((document: any, index: number) => {
            console.log(index, userDetails?.accreditedinvestordata[document]);
            // const fileName = document?.document_url;
            // ``;
            const fileExtension = userDetails?.accreditedinvestordata[document]?.split('.').pop();
            return (
              <div key={index} className='alignment flex-col'>
                <div className='mb-3'>
                  {fileExtension === 'pdf' ? (
                    <PdfPreview url={userDetails?.accreditedinvestordata[document]} />
                  ) : (
                    <DocumentImage src={userDetails?.accreditedinvestordata[document]} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <br />
        <br />
        <div className='alignmentAround m-4 flex-wrap float-left'>
          <div>
            <Details heading='Document Name' detail={userDetails?.addressinfodata && 'Address Verification Document'} />
            <DocumentImage src={userDetails?.addressinfodata.docFile} />
          </div>
          <br />
          <br />
          <div>
            <Details heading='Document Name' detail={userDetails?.businessentitydata && 'Authorization To Act On Behalf Of The Entity'} />
            <DocumentImage src={userDetails?.businessentitydata.image_url} />
          </div>
          <br />
          <br />
          <div>
            <Details heading='Document Name' detail={userDetails?.kycinfodata && 'Identification Document'} />
            <DocumentImage src={userDetails?.kycinfodata.frontImageUrl} />
          </div>
        </div>
      </div> */}
      <div className='mt-6 px-6'>
        <div className='grid grid-cols-2'>
          {/* KYC Information */}
          <div>
            <Details heading='Document Name' detail={userDetails?.kycinfodata && 'Identification Document'} />
            {userDetails?.kycinfodata?.frontImageUrl && (
              <div className='mb-3'>
                {userDetails.kycinfodata.frontImageUrl.split('.').pop() === 'pdf' ? (
                  <PdfPreview url={userDetails.kycinfodata.frontImageUrl} />
                ) : (
                  <DocumentImage src={userDetails.kycinfodata.frontImageUrl} />
                )}
              </div>
            )}
          </div>
          {/* Address Verification Document */}
          <div>
            <Details heading='Document Name' detail={userDetails?.addressinfodata && 'Address Verification Document'} />
            {userDetails?.addressinfodata?.docFile && (
              <div className='mb-3'>
                {userDetails.addressinfodata.docFile.split('.').pop() === 'pdf' ? (
                  <PdfPreview url={userDetails.addressinfodata.docFile} />
                ) : (
                  <DocumentImage src={userDetails.addressinfodata.docFile} />
                )}
              </div>
            )}
          </div>

          {/* Authorization Document */}
          <div>
            <Details heading='Document Name' detail={userDetails?.businessentitydata && 'Authorization To Act On Behalf Of The Entity'} />
            {userDetails?.businessentitydata?.image_url && (
              <div className='mb-3'>
                {userDetails.businessentitydata.image_url.split('.').pop() === 'pdf' ? (
                  <PdfPreview url={userDetails.businessentitydata.image_url} />
                ) : (
                  <DocumentImage src={userDetails.businessentitydata.image_url} />
                )}
              </div>
            )}
          </div>
          <div>
            <Details heading='Document Name' detail={userDetails?.accreditedinvestordata && 'Accredited Investor'} />
            {Object.keys(userDetails?.accreditedinvestordata)?.map((document: any, index: number) => {
              const documentUrl = userDetails?.accreditedinvestordata[document].image_url;

              if (typeof documentUrl === 'string') {
                const fileExtension = documentUrl.split('.').pop();
                return (
                  <div key={index} className='alignment flex-col'>
                    <div className='mb-3'>
                      {fileExtension === 'pdf' ? <PdfPreview url={documentUrl} /> : <DocumentImage src={documentUrl} />}
                    </div>
                  </div>
                );
              } else if (typeof documentUrl === 'object' && documentUrl !== null) {
                // Handle object case, assuming the object might have a URL or file path
                const fileExtension = documentUrl.url?.split('.').pop(); // Adjust this based on actual object structure
                return (
                  <div key={index} className='alignment flex-col'>
                    <div className='mb-3'>
                      {fileExtension === 'pdf' ? (
                        <PdfPreview url={documentUrl.url} /> // Adjust based on the object's structure
                      ) : (
                        <DocumentImage src={documentUrl.url} /> // Adjust based on the object's structure
                      )}
                    </div>
                  </div>
                );
              } else {
                console.error(`Unexpected type for document: ${typeof documentUrl}`);
                return null; // Return null if the documentUrl is not a string or valid object
              }
            })}
          </div>
          {/* {userDetails?.other_documents?.map((document: OtherDocumentProps, index: number) => {
            const fileName = document?.document_url;
            const fileExtension = fileName?.split('.').pop();
            return (
              <div key={index} className='alignment flex-col'>
                <Details heading='Document Name' detail={document?.document_name} />
                <div className='mb-3'>
                  {fileExtension === 'pdf' ? <PdfPreview url={document.document_url} /> : <DocumentImage src={document.document_url} />}
                </div>
              </div>
            );
          })} */}
        </div>
        <UploadOtherDocument userDetails={userDetails} userId={userId} documentType='other' />
      </div>
    </CollapsibleContainer>
  );
};

export default allDocument;
