import React, { useCallback, useState, useRef } from 'react';
import Table from '../../Table/table';
import './iraPanel.css';
import { APPLICATION_DURATION, APPLICATION_STATUS, exceptThisSymbols, SEARCH_TYPES } from '../../../../Constant/IRAPanelData';
import Dropdown from '../../../../components/dropdown/dropdown';
import HeaderBar from '../../../../components/headerBar/headerBar';
import HeaderCard from '../../../../components/Sections/HomeScreen/headerCard';
import { MdSearch } from 'react-icons/md';
import { IRA_ADMIN } from '../../../../Api/panelApis';
import { ThreeDots } from 'react-loader-spinner';
import Button from '../../../../components/Buttons/button';
import { SearchText } from '../../../../Utils/helper';

interface UsersProps {
  application_status: string;
  country: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  phone_number: string;
  started_at: string;
}

const Users = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const route = window?.location?.href?.split('/').at(-1);
  const [applicationStatus, setApplicationStatus] = React.useState('ALL');
  const [users, setUsers] = React.useState([]);
  const [searchData, setSearchData] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = useState(1);
  const [searchResult, setSearchResult] = useState([]);
  const [visited, setVisited] = useState<number[]>([]);
  const [searchType, setSearchType] = useState('first_name');
  const [isSearch, setIsSearch] = useState(false);
  const [triverse, setTriverse] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [duration, setDuration] = useState('ALL TIME');
  const [isFilter, setIsFilter] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [appStatus, setAppStatus] = useState({
    incompleteUsers: '',
    inProcess: '',
    approved: '',
    resubmit: '',
    notStarted: '',
    decline: '',
    total: 0,
  });
  const [filteredAppStatus, setfilteredStatus] = useState({
    incompleteUsers: '',
    inProcess: '',
    approved: '',
    resubmit: '',
    notStarted: '',
    decline: '',
    total: 0,
  });

  const MAX_RECORDS_PER_PAGE = 50;

  const filterData = users?.sort((a: { id: number }, b: { id: number }) => b.id - a.id);

  const applications =
    applicationStatus === 'ALL'
      ? isFilter
        ? filteredUsers
        : filterData.slice((page - 1) * 50, page < triverse ? visited[page - 1] : users?.length)
      : isFilter
      ? filteredUsers.filter((user: UsersProps) => {
          return (
            applicationStatus === user.application_status ||
            (applicationStatus === 'IN PROCESS' && user.application_status === 'BANK APPROVED')
          );
        })
      : filterData.slice((page - 1) * 50, page < triverse ? visited[page - 1] : users?.length).filter((user: UsersProps) => {
          return (
            applicationStatus === user.application_status ||
            (applicationStatus === 'IN PROCESS' && user.application_status === 'BANK APPROVED')
          );
        });

  // Slice the applications array to display only 50 users per page
  const displayUsers = isSearch ? searchResult : applications;
  // .slice((page - 1) * MAX_RECORDS_PER_PAGE, page * MAX_RECORDS_PER_PAGE);

  const getAllUser = useCallback(
    async (signal, route) => {
      if (duration === 'user_application_ninety_day' || duration === 'user_application_thirty_day') {
        setIsFilter(true);
        setIsLoading(true);
        const response = await IRA_ADMIN.getFilteredUsers(signal, duration, route);
        const {
          data,
          count: { approved, declined, in_process, onboarding_pending, bank_approved, resubmit, total_users },
        }: any = response;
        setIsLoading(false);
        setFilteredUsers(data);
        setfilteredStatus({
          ...filteredAppStatus,
          approved,
          decline: declined,
          inProcess: in_process + bank_approved,
          incompleteUsers: onboarding_pending,
          resubmit: resubmit,
          total: total_users,
        });
      } else if (page <= triverse && visited[page - 1]) {
        setIsFilter(false);
        return [users, appStatus];
      } else {
        setIsFilter(false);
        setIsLoading(true);
        const response = await IRA_ADMIN.getAllUsers(signal);
        const {
          data,
          count: { approved, declined, in_process, onboarding_pending, bank_approved, not_started, resubmit, total_users },
        }: any = response;
        setIsLoading(false);
        setUsers(data);
        setVisited([...visited, data?.length]);
        setAppStatus({
          ...appStatus,
          approved,
          decline: declined,
          inProcess: in_process + bank_approved,
          incompleteUsers: onboarding_pending,
          notStarted: not_started,
          resubmit: resubmit,
          total: total_users,
        });
        setTotalPages(Math.ceil(total_users / 50));
      }
    },
    [page, triverse, duration],
  );

  // const getRoute = window?.location?.href;
  // const directory = getRoute?.split('/').at(-1);

  const searchUser = async (searchValue: any) => {
    const search = {
      [searchType]: searchType === 'phone_number' ? '+' + searchValue : searchValue,
      applications: true,
    };
    try {
      setIsLoading(true);
      const res = await IRA_ADMIN.searchUser(search);
      setIsLoading(false);
      setSearchResult(res);
      setIsSearch(true);
    } catch (err) {
      console.log({ err });
    }
  };

  React.useEffect(() => {
    setPage(1);
    setTriverse(1);
    setVisited([]);
    setUsers([]);
    setTotalPages(1);
  }, [route]);

  const { incompleteUsers, inProcess, approved, resubmit, notStarted, decline } = isFilter ? filteredAppStatus : appStatus;

  const cardsData = {
    incompleteUsers,
    inProcess,
    approved,
    resubmit,
    notStarted,
    decline,
  };

  React.useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
    setIsSearch(false);
    const controller = new AbortController();
    getAllUser(controller.signal, route);
    window.scrollTo({ top: 0, left: 0 });
    return () => {
      controller.abort();
    };
  }, [getAllUser, route]);

  const next = () => {
    setPage(current => Math.min(current + 1, totalPages)); // Ensures you can't go beyond total pages
  };

  const previous = () => {
    setPage(current => Math.max(current - 1, 1)); // Ensures you can't go below page 1
  };

  const submit = (e: any) => {
    e.preventDefault();
    searchUser(searchData);
  };

  const onChange = (e: any) => {
    setSearchData(e.target.value);
    if (!e.target.value.length) {
      setIsSearch(false);
    }
  };

  React.useEffect(() => {
    if (!isSearch && !isLoading) {
      // Calculate total pages based on the users displayed and not the filtered applications list
      const totalRecords = isFilter ? filteredUsers.length : users.length;
      setTotalPages(Math.ceil(totalRecords / MAX_RECORDS_PER_PAGE));
    }
  }, [isSearch, isLoading, isFilter, filteredUsers, users]);

  return (
    <div className='panelTableContainer'>
      <HeaderBar title='Onboarding Application' totalApplication={isFilter ? filteredAppStatus.total : appStatus?.total} />
      <HeaderCard isLoading={isLoading} setApplicationStatus={setApplicationStatus} cardsData={cardsData} />
      <div className='panelTable'>
        <div className='dropDownSection'>
          <div className='panelHeadingContainer'>
            <div className='flex '>
              <form onSubmit={submit} className='searchInputContainer'>
                <input
                  ref={inputRef}
                  type={searchType === 'phone_number' || searchType === 'user_id' ? 'number' : 'text'}
                  placeholder={`Search by ${SearchText(searchType)}`}
                  onChange={onChange}
                  onKeyDown={e =>
                    (searchType === 'phone_number' || searchType === 'user_id') && exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }
                  className='searchInput'
                  required
                />
                <button type='submit' onSubmit={submit}>
                  <MdSearch size={24} color='lightgrey' className='cursor-pointer mx-2' />
                </button>
              </form>
              <div>
                <Dropdown items={SEARCH_TYPES} selected={searchType} captionKey='value' className='dropdownInput' handler={setSearchType} />
              </div>
            </div>
            <div>
              <Dropdown
                items={APPLICATION_DURATION}
                selected={duration}
                captionKey='value'
                className='dropdownInput'
                handler={setDuration}
              />
              <Dropdown
                items={APPLICATION_STATUS}
                selected={applicationStatus}
                captionKey='value'
                className='dropdownInput'
                handler={setApplicationStatus}
              />
            </div>
          </div>
          {isLoading ? (
            <div className='alignment h-full' style={{ height: '70vh' }}>
              <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
            </div>
          ) : (
            <Table userData={displayUsers} page={page} MAX_RECORDS_PER_PAGE={MAX_RECORDS_PER_PAGE} />
          )}
          {!isSearch && !isLoading && !isFilter && (
            <div className='flex justify-between mx-4 my-6'>
              <Button text='Previous' onClick={previous} disabled={page <= 1} />
              <p className='font-bold'>
                Page: {page}/{totalPages}
              </p>
              <Button text='Next' onClick={next} disabled={page >= totalPages} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Users;
