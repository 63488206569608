/* eslint-disable no-prototype-builtins */
import React from 'react';
import HeaderBar from '../../components/headerBar/headerBar';
import Button from '../../components/Buttons/button';
import { MdModeEditOutline } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './userDetails.css';
import Modal from '../../components/Modal/modal';
import { STATUS } from '../../Constant/IRAPanelData';
import { IRA_ADMIN } from '../../Api/panelApis';
import { ThreeDots } from 'react-loader-spinner';
// import StatusMessage from '../../components/StatusMessage/StatusMessage';
import StatusButton from '../../components/StatusMessage/StatusButton';
import PersonalInfo from './Section/personalInfo';
import AccountInfo from './Section/accountInfo';
import AddressInfo from './Section/addressInfo';
// import KYCSection from './Section/kycSection';
// import OtherDocument from './Section/otherDocument';
import { toastError, toastSuccess } from '../../Utils/helper';
import { AppContext } from '../../Context/Context';
// import AuthProofDoc from './Section/authProofDoc';
import PortfolioInfo from './Section/portfolioInfo';
import TransactionInfo from './Section/TransactionInfo';
import UserTransactionInfo from './Section/UserTransactionInfo';
import DwollaLogs from './Section/DwollaLogs';
// import Details from '../../components/Details/details';
import Notes from './Section/NotesInfo';
import InvestorStatus from './Section/investorStatus';
import AllDocument from './Section/allDocuments';
import ThirdParty from './Section/thirdParty';
const UserDetails: React.FC = () => {
  const [userId, setUserId] = React.useState('');
  const [isModalOpen, setisModalOpen] = React.useState(false);
  const [isapproveModalOpen, setIsApproveModalOpen] = React.useState(false);
  const [userData, setUserData] = React.useState<any>([]);
  const [portfolioData, setPortfolioData] = React.useState<any>();
  const [transactionData, setTransactionData] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isInformUser, setIsInformUser] = React.useState(true);
  const [strategies, setStrategies]: any = React.useState([]);
  const [logs, setLogs] = React.useState([]);
  const [notes, setNotes] = React.useState([]);
  const { infoUpdated, setInfoUpdated } = React.useContext(AppContext);
  const navigate = useNavigate();

  const location = useLocation();
  const { id: paramId } = useParams();
  const details: any = location.state;
  const userDetails = userData.data;

  const getUserDetails = async (id: number) => {
    const response = await IRA_ADMIN.getUser(id);
    setUserData(response);
  };
  const fullname = `${userData?.data?.personalinfodata?.first_name} ` + ` ${userData?.data?.personalinfodata?.last_name}`;
  const path = window.location.href.includes('UserDetailsInvestors')
    ? `Viewing Verified Investor: ${fullname}`
    : `Application for ${fullname}`;

  const getLogsDetails = async (id: number) => {
    const response = await IRA_ADMIN.getLogs(id);
    setLogs(response);
  };

  const getNotesDetails = async (id: number) => {
    const response = await IRA_ADMIN.getNotes(id);
    setNotes(response.filter((val: any) => val.description).sort((a: any, b: any) => b.id - a.id));
  };

  const getTransactionDetails = async (id: number) => {
    const response = await IRA_ADMIN.getUserSpecificTransactions(id);
    setTransactionData(response);
  };

  const getPortfolioDetails = async (id: number) => {
    const response = await IRA_ADMIN.getPortfolio(id);
    setPortfolioData(response);
    const getAll = await IRA_ADMIN.getAllStrategies();
    const strategyWithID: any = [];
    getAll?.map((val: any) => {
      strategyWithID.push({ strategyName: val.title, id: val.id });
    });
    const allocationsWithStrategy: any = [];
    strategyWithID?.forEach((str: any) => {
      response?.allocations?.find((val: any) => {
        if (str.id === val.strategy_id) {
          allocationsWithStrategy.push({ strategyName: str.strategyName, allocation: val.alloc });
          return true;
        }
      });
    });
    setStrategies(allocationsWithStrategy);
    setIsLoading(false);
  };
  const status = userDetails?.personalinfodata.application_status;
  const investor_type = userDetails?.personalinfodata.investor_type;
  const demo_user = userDetails?.personalinfodata.demo_user ?? true;

  React.useEffect(() => {
    const id = paramId || (details ? JSON.parse(details).id : null);

    if (id) {
      getUserDetails(Number(id));
      getPortfolioDetails(Number(id));
      getTransactionDetails(Number(id));
      getLogsDetails(Number(id));
      getNotesDetails(Number(id));
      setUserId(id);
      window.scrollTo({ top: 0, left: 0 });
    }
  }, [details, infoUpdated, paramId]);

  const approveHandler = async () => {
    try {
      const response = await IRA_ADMIN.approveUserApplication(Number(userId), investor_type);
      if (response.Success || response.success) {
        toastSuccess('Approved Application Successfully');
        setInfoUpdated(!infoUpdated);
        setIsApproveModalOpen(false);
      }
    } catch (error: any) {
      toastError(error.response.data.message);
    }
  };

  const rejectHandler = async () => {
    try {
      const response = await IRA_ADMIN.rejectUserApplication(Number(userId), investor_type);
      if (response.Success || response.success) {
        toastSuccess('Reject Application Successfully');
        setInfoUpdated(!infoUpdated);
        setisModalOpen(false);
      }
    } catch (error: any) {
      toastError(error.response.data.message);
    }
  };
  const handleTransaction = () =>
    navigate('/addFunds', {
      state: {
        id: userId,
      },
    });
  const pathUrl = window.location.href;
  return (
    <>
      <div className='w-full max-w-8xl'>
        <HeaderBar title={path} pending={userDetails?.pending_from} />
        <div className='inline-block w-full max-w-8xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform rounded-2xl '>
          <div className='w-full max-w-8xl p-6 my-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2x2'>
            {isLoading ? (
              <div className='alignment h-full' style={{ height: '80vh' }}>
                <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
              </div>
            ) : (
              <>
                <div className='alignmentBetween mx-4 py-2 px-2'>
                  <StatusButton status={status} />
                  <button
                    className='px-4 py-2 alignment rounded-md text-white	font-normal bg-darkblue '
                    onClick={() => {
                      navigate('/editDetails', {
                        state: {
                          portfolioDetail: portfolioData,
                          userDetails: userDetails,
                          userId: userId,
                          strategies,
                        },
                      });
                    }}
                  >
                    <MdModeEditOutline size={18} className='mr-1' />
                    Edit
                  </button>
                </div>
                <Modal
                  setisModalOpen={setisModalOpen}
                  isModalOpen={isModalOpen}
                  name={`${userDetails?.personalinfodata.first_name}  ${userDetails?.personalinfodata.last_name}`}
                  email={userDetails?.personalinfodata.email}
                  userId={userId}
                  isInformUser={isInformUser}
                  rejectHandler={rejectHandler}
                  setInfoUpdated={setInfoUpdated}
                  InfoUpdated={infoUpdated}
                />
                <Modal
                  setisModalOpen={setIsApproveModalOpen}
                  isModalOpen={isapproveModalOpen}
                  name={`${userDetails?.personalinfodata.first_name}  ${userDetails?.personalinfodata.last_name}`}
                  email={userDetails?.personalinfodata.email}
                  userId={userId}
                  isInformUser={isInformUser}
                  approveModal={true}
                  approveHandler={approveHandler}
                  setInfoUpdated={setInfoUpdated}
                  InfoUpdated={infoUpdated}
                />
                {/* Personal Info Section */}
                <PersonalInfo userDetails={userDetails} />
                {/* Account Info Section */}
                {userDetails?.accountinfo?.ira_account_type ? <AccountInfo userDetails={userDetails} /> : null}
                {/* Address Info Section */}
                {userDetails?.addressinfodata?.hasOwnProperty('address1') ? <AddressInfo userDetails={userDetails} /> : null}
                {pathUrl.includes('UserDetailsInvestors') ? <InvestorStatus userDetails={userDetails} userId={userId} /> : null}
                {/* KYC Info Section */}
                {/* {userDetails?.kycinfodata.documentType ? (
                  <KYCSection
                    applicationStatus={status}
                    userDetails={userDetails}
                    setisModalOpen={setisModalOpen}
                    setIsInformUser={setIsInformUser}
                  />
                ) : null} */}
                {/* all Documents */}
                {pathUrl.includes('UserDetailsApplications') ? <AllDocument userDetails={userDetails} userId={userId} /> : null}
                {pathUrl.includes('UserDetailsApplications') ? <ThirdParty userDetails={userDetails} userId={userId} /> : null}
                {/* {userDetails?.personalinfodata.investor_type === Investor_Type.NON_US_ENTITY ||
                userDetails?.personalinfodata.investor_type === Investor_Type.US_ENTITY ? (
                  <AuthProofDoc userDetails={userDetails} />
                ) : null} */}
                {/* {userDetails?.other_documents.length ? <OtherDocument userDetails={userDetails} /> : null} */}
                <DwollaLogs logs={logs} />
                {/* {status === STATUS.APPROVED || status === STATUS.DECLINED ? (
                  <Details heading='Addtional Notes' detail={userDetails?.personalinfodata?.message || '-'} />
                ) : null} */}
                <Notes notes={notes} setInfoUpdated={setInfoUpdated} infoUpdated={infoUpdated} userId={userId} />
                <div className='my-6'>
                  {/* {(status === STATUS.PENDING || status === STATUS.BANK_APPROVED) && demo_user ? ( */}
                  {/* <> */}
                  {userDetails?.personalinfodata?.application_status !== 'APPROVED' && (
                    <div className='alignment'>
                      <Button
                        text='APPROVE'
                        style='bg-darkblue border-2 border-transparent m-4 rounded-md px-4'
                        onClick={() => {
                          setIsApproveModalOpen(true), setIsInformUser(false);
                        }}
                      />
                      <Button
                        text='REJECT'
                        style='bg-red text-white border-2 border-transparent rounded-md hover:bg-red  m-4 hover:opacity-70 focus:ring-red '
                        onClick={() => {
                          setisModalOpen(true), setIsInformUser(false);
                        }}
                      />
                    </div>
                  )}
                  {/* </> */}
                  {/* ) : ( */}
                  {/* <>
                      <StatusMessage status={status} />
                    </>
                  )} */}
                </div>
                {status === STATUS.APPROVED ? (
                  <PortfolioInfo
                    setInfoUpdated={setInfoUpdated}
                    infoUpdated={infoUpdated}
                    portfolioDetail={portfolioData}
                    strategies={strategies}
                    userId={userId}
                  />
                ) : null}
                {transactionData?.length ? <UserTransactionInfo transaction={transactionData} userId={userId} /> : null}
                {!demo_user && status !== STATUS.DECLINED ? <TransactionInfo handleTransaction={handleTransaction} /> : null}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
