import * as React from 'react';
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlinePlus } from 'react-icons/ai';
import { Oval } from 'react-loader-spinner';
import DocumentImage from '../../../components/DocumentImage/documentImage';
import PdfImage from '../../../components/DocumentImage/pdfImage';
import EditableInput from '../../../components/InputFields/EditableInput';
import EditableFiles from '../../../components/InputFields/EditFiles';
import { PDF_MESSAGE } from '../../../Constant/IRAPanelData';
import DeleteOtherDocument from './deleteOtherDocument';
import { toastError, toastSuccess } from '../../../Utils/helper';
import { IRA_ADMIN } from '../../../Api/panelApis';
import { useNavigate } from 'react-router-dom';
interface OtherDocumentFileProps {
  type: string;
  name: string;
}
interface OtherDocumentProps {
  documentName: string;
  otherDocumentFile: OtherDocumentFileProps;
  otherDocumentFileURL: string;
}
const UploadOtherDocument = ({ userDetails, userId, documentType }: any) => {
  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const [otherDocument, setOtherDocument] = React.useState<any>([{ documentName: '', otherDocumentFile: '', otherDocumentFileURL: '' }]);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const handleInputChange = (e: any, index: number, type: string) => {
    if (type === 'name') {
      const { name, value } = e.target;
      const list = [...otherDocument];
      list[index][name] = value;
      setOtherDocument(list);
    } else {
      const { name } = e.target;
      const value = e.target.files[0];
      const list = [...otherDocument];
      list[index][name] = value;
      setOtherDocument(list);
    }
  };
  const handleRemoveClick = (index: number) => {
    const list = [...otherDocument];
    list.splice(index, 1);
    setOtherDocument(list);
  };

  const handleAddClick = () => {
    setOtherDocument([...otherDocument, { documentName: '', otherDocumentFile: '' }]);
  };

  const convertBase64 = (image: Blob, setValue: any, index: number) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(image);
      try {
        fileReader.onload = () => {
          resolve(fileReader.result);
          if (setValue === 'otherDocumentFileURL') {
            const list = [...otherDocument];
            list[index][setValue] = fileReader.result;
            setOtherDocument(list);
          } else {
            setValue(fileReader.result);
          }
        };
      } catch {
        fileReader.onerror = (error: any) => {
          reject(error);
        };
      }
    });
  };
  const otherDocsHandler = async (i: number) => {
    setLoading(true);
    console.log('otherDocument[i].otherDocumentFile new', otherDocument[i].otherDocumentFile);
    const data = {
      document_name: otherDocument[i].documentName,
      other_document: otherDocument[i].otherDocumentFile,
    };
    try {
      let response;
      if (documentType === 'other') {
        response = await IRA_ADMIN.otherDocument(data, parseInt(userId));
      } else if (documentType === 'thirdParty') {
        response = await IRA_ADMIN.thirdPartyDocument(data, parseInt(userId));
      }
      if (response.success) {
        toastSuccess(response.message);
        setLoading(false);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    } catch (error: any) {
      toastError(error.response.data.error || error.response.data.message);
      setLoading(false);
    }
  };
  const handleClick = () => {
    console.log('Clicked');
  };

  return (
    <div>
      {documentType === 'other' && <h2 className='text-lg font-medium mt-8 heading'>OTHER DOCUMENTS</h2>}
      <div className='mt-6 px-6'>
        {userDetails?.other_documents && userDetails?.other_documents.length > 0 && documentType === 'other' && (
          <DeleteOtherDocument userId={userId} userDetails={userDetails} documentType='other' />
        )}
        {userDetails?.third_party_documents && userDetails?.third_party_documents.length > 0 && documentType === 'thirdParty' && (
          <DeleteOtherDocument userId={userId} userDetails={userDetails} documentType='thirdParty' />
        )}
      </div>
      {otherDocument.map((document: OtherDocumentProps, i: number) => {
        return (
          <>
            <div className='alignmentAround mt-10 flex-wrap'>
              <EditableInput
                heading='Document Title:'
                type='text'
                value={document.documentName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, i, 'name')}
                className='inputStyle'
                name='documentName'
              />
              <div className='alignment flex-col'>
                {document.otherDocumentFileURL ? (
                  document.otherDocumentFile?.type === 'application/pdf' ? (
                    <div className='alignment'>
                      <div className='mt-8 imageContainer'>
                        <PdfImage title={document.otherDocumentFile?.name} />
                      </div>
                      <div className='alignment w-12'>
                        {otherDocument.length !== 1 && (
                          <button className='ml-3 mt-5' onClick={() => handleRemoveClick(i)}>
                            <AiFillCloseCircle color='red' size={22} />
                          </button>
                        )}
                        <button
                          className='ml-3 mt-5 sm:mr-0 disabled:cursor-not-allowed'
                          onClick={() => {
                            setCurrentIndex(i);
                            otherDocsHandler(i);
                          }}
                          disabled={!document.documentName || !document.otherDocumentFile || loading}
                        >
                          {loading && i === currentIndex ? (
                            <Oval color='green' height={24} width={24} />
                          ) : (
                            <AiFillCheckCircle color='green' size={22} />
                          )}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className='alignment'>
                        {document.otherDocumentFile ? (
                          <DocumentImage src={document.otherDocumentFileURL} />
                        ) : (
                          <EditableFiles
                            name='otherDocumentFile'
                            type='file'
                            value={document.otherDocumentFile}
                            onChange={(e: any) => {
                              {
                                convertBase64(e.target.files[0], 'otherDocumentFileURL', i), handleInputChange(e, i, 'file');
                              }
                            }}
                            placeholder={PDF_MESSAGE}
                            title={'Upload'}
                            parentStyle={'bg-grey h-52 p-5 rounded-lg lg:mr-8'}
                            uploadButtonStyle={'bg-pink'}
                            docTypes={'application/pdf'}
                            onclick={handleClick}
                          />
                        )}
                        <div className='alignment w-12'>
                          {otherDocument.length !== 1 && (
                            <button className='ml-3 mt-5' onClick={() => handleRemoveClick(i)}>
                              <AiFillCloseCircle color='red' size={22} />
                            </button>
                          )}
                          <button
                            className='ml-3 mt-5 sm:mr-0 disabled:cursor-not-allowed'
                            onClick={() => {
                              setCurrentIndex(i);
                              otherDocsHandler(i);
                            }}
                            disabled={!document.documentName || !document.otherDocumentFile || loading}
                          >
                            {loading && i === currentIndex ? (
                              <Oval color='green' height={24} width={24} />
                            ) : (
                              <AiFillCheckCircle color='green' size={22} />
                            )}
                          </button>
                        </div>
                      </div>
                    </>
                  )
                ) : null}
                {document.otherDocumentFile || !document.otherDocumentFileURL ? (
                  <EditableFiles
                    name='otherDocumentFile'
                    type='file'
                    value={document.otherDocumentFile}
                    onChange={(e: any) => {
                      {
                        convertBase64(e.target.files[0], 'otherDocumentFileURL', i), handleInputChange(e, i, 'file');
                      }
                    }}
                    placeholder={document.otherDocumentFileURL ? null : PDF_MESSAGE}
                    title={document.otherDocumentFileURL ? 'Change Document' : 'Upload'}
                    parentStyle={document.otherDocumentFileURL ? 'mr-8' : 'bg-grey h-52 p-5 rounded-lg lg:mr-16'}
                    uploadButtonStyle={document.otherDocumentFileURL ? 'bg-transparent text-darkblue w-40' : 'bg-pink'}
                    docTypes={'application/pdf'}
                    onclick={handleClick}
                  />
                ) : null}
              </div>
            </div>
            {otherDocument.length - 1 === i && (
              <button
                disabled={!document.documentName || !document.otherDocumentFile}
                className=' text-darkblue ml-3 mt-2 font-extrabold alignment disabled:cursor-not-allowed'
                onClick={handleAddClick}
              >
                <AiOutlinePlus className='mr-1' />
                Add More
              </button>
            )}
          </>
        );
      })}
    </div>
  );
};

export default UploadOtherDocument;
