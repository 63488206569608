import { Listbox } from '@headlessui/react';
import { useMemo, useState } from 'react';
import { MdRemoveRedEye } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';
import { Transaction_COLUMNS } from '../../../Constant/Column/column';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

const TransactionTable = ({ transactionsList, route }: any) => {
  const [checkButton, setcheckButton] = useState('');

  // Custom sorting function for case insensitive sorting
  const caseInsensitiveSort = (rowA: any, rowB: any, columnId: string) => {
    const valueA = rowA.values[columnId].toString().toLowerCase();
    const valueB = rowB.values[columnId].toString().toLowerCase();
    return valueA.localeCompare(valueB);
  };

  const columns = useMemo<any>(() => {
    return Transaction_COLUMNS.map(column => {
      // if (!column.disableSortBy) {
      return {
        ...column,
        // Use the custom sorting function for columns that allow sorting
        sortType: caseInsensitiveSort,
      };
      // }
      // return column;
    });
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: transactionsList,
    },
    useSortBy,
  );

  const navigate = useNavigate();
  const handleCick = (data: any) => {
    const notOrifignaldata = data.map((item: any) => ({
      row: { cells: item.row.cells.map((cell: any) => ({ key: cell?.column?.Header || 'KEY', value: cell.value })) },
    }));
    navigate('/transactionDetail', { state: { data: notOrifignaldata, route } });
  };

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, colKey) => {
                if (
                  column?.render('Header') === 'Transaction Start Date' ||
                  column?.render('Header') === 'Transaction End Date' ||
                  column?.render('Header') === 'Details'
                ) {
                  return null;
                }
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} key={colKey}>
                    <div className='flex items-center justify-center mx-auto'>
                      <span>{column.render('Header')}</span>
                      {column.disableSortBy ? null : column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaSortDown className='ml-1' />
                        ) : (
                          <FaSortUp className='ml-1' />
                        )
                      ) : (
                        <FaSort className='ml-1' />
                      )}
                    </div>
                  </th>
                );
              })}
              <th>View</th>
            </tr>
          ))}
        </thead>
        {transactionsList?.length ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell: any, colKey) => {
                      if (
                        cell?.render('Cell')?.props?.column?.Header === 'Transaction Start Date' ||
                        cell?.render('Cell')?.props?.column?.Header === 'Transaction End Date' ||
                        cell?.render('Cell')?.props?.column?.Header === 'Details'
                      )
                        return;
                      return (
                        <td {...cell.getCellProps()} key={colKey}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                    <td className='text-center'>
                      <Listbox value={checkButton} onChange={setcheckButton}>
                        <Listbox.Button className='text-center mx-auto'>
                          <div
                            onClick={() => {
                              handleCick(row.cells);
                            }}
                          >
                            <MdRemoveRedEye />
                          </div>
                        </Listbox.Button>
                      </Listbox>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <th className='relative left-[40%]'>No matching records found</th>
            </tr>
          </tbody>
        )}
      </table>
    </>
  );
};

export default TransactionTable;
