import React from 'react';
import { STATUS } from '../../Constant/IRAPanelData';
import StatusPill from '../StatusPill/statusPill';

const StatusCell: React.FC<{ value: string }> = ({ value }) => {
  const StatusPills = (value: string) => {
    switch (value) {
      case STATUS.APPROVED:
        return <StatusPill textColor='text-darkGreen' backgroundcolor='bg-lightGreen' status={'Approved'} style='w-36 mx-auto' />;
      case STATUS.PENDING:
      case STATUS.BANK_APPROVED:
        return <StatusPill textColor='text-pendingDarkBtn' backgroundcolor='bg-pendingLightBtn' status={'Pending'} style='w-36 mx-auto' />;
      case STATUS.ONBOARDING_PENDING:
        return <StatusPill textColor='text-lightBlue' backgroundcolor='bg-darkBlue' status={'Incomplete'} style='w-36 mx-auto' />;
      case STATUS.NOT_STARTED:
        return <StatusPill textColor='text-lightBlue' backgroundcolor='bg-darkBlue' status={'Incomplete'} style='w-36 mx-auto' />;
      case STATUS.BANK_PENDING:
        return <StatusPill textColor='text-[#c922c4]' backgroundcolor='bg-[#f7c3f6]' status={'Dwolla Pending'} style='w-36 mx-auto' />;
      case STATUS.RESUBMIT:
        return <StatusPill textColor='text-darkOrange' backgroundcolor='bg-lightOrange' status={'Resubmit'} style='w-36 mx-auto' />;
      case STATUS.DECLINED:
        return <StatusPill textColor='text-darkRed' backgroundcolor='bg-lightRed' status={'Rejected'} style='w-36 mx-auto' />;
    }
  };

  return <span>{StatusPills(value)}</span>;
};

export default StatusCell;
